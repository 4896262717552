<template>
  <Splide
    :options="options"
    :extensions="extensions"
    id="carousel-container"
    @splide:move="stopVideoOnSwipe()"
  >
    <SplideSlide
      v-for="profile in videoProfiles"
      :key="profile.id"
      :data-splide-vimeo="profile.videoFull"
      class="carousel__item"
      @click="selected = true"
    >
      <img :src="profile.poster" loading="lazy" :alt="profile.name" />
      <div
        v-if="selected != true"
        class="video-caption"
        :class="profile.name.replace(/\s+/g, '')"
      >
        <h3>{{ profile.name }}</h3>
        <p>{{ profile.description }}</p>
        <!-- <transition name="fade">
            <button v-if="profile.hover">Full Video</button></transition
          > -->
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Video } from "@splidejs/splide-extension-video";
import { defineComponent } from "vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";

export default defineComponent({
  name: "VideoExample",

  components: {
    Splide,
    SplideSlide,
  },

  setup() {
    const options = {
      rewind: true,
      //   disablePictureInPicture: true,
      //   disableOverlayUI: true,

      //   heightRatio: 0.5625,
    };

    return {
      options,
      extensions: { Video },
    };
  },
  data() {
    return {
      selected: undefined,

      videoProfiles: [
        {
          id: 1,
          hover: true,
          buttonHover: false,
          fullVideo: false,
          firstVideo: true,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/11-25-24/rayhanah-still.jpg",

          name: "Rayhanah Ahmed",
          caption:
            "https://tcnj.edu/custom/homepage/captions/11-25-24/rayhanah.vtt",
          description:
            "TCNJ’s elevated, hands-on academic experience means Rayhanah feels more than ready to start her nursing career.",
          videoFull: "https://vimeo.com/1030847097",
        },
        {
          id: 2,
          hover: false,
          buttonHover: false,
          fullVideo: false,

          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/11-25-24/osvaldo-still-final.jpg",
          name: "Osvaldo Martinez-Pineda",
          caption:
            "https://tcnj.edu/custom/homepage/captions/11-25-24/osvaldo.vtt",
          description:
            "Osvaldo hit his stride — and made the Dean’s List — thanks in part to the Center for Student Success.",
          videoFull: "https://vimeo.com/1030847069",
        },
        {
          id: 3,
          hover: false,
          buttonHover: false,
          fullVideo: false,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/11-25-24/claudia-still-final.jpg",
          name: "Claudia Adolf",
          caption:
            "https://tcnj.edu/custom/homepage/captions/11-25-24/claudia.vtt",
          description:
            "It took Claudia only a week and a club fair to find her community of friends and organizations after transferring to TCNJ. ",
          videoFull: "https://vimeo.com/1030847035",
        },
      ],
    };
  },
  methods: {
    stopVideoOnSwipe() {
      console.log("swipe");
      // videoProfiles.fullVideo = false;
      this.selected = undefined;
      // profile.fullVideo = false;

      // profile.fullVideo = false;
    },
  },
});
</script>
